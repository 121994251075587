import { Test } from '../interfaces/test';

export function getTestMaxPoint(test: Test): number {
  let maxPoints = 0;

  maxPoints += test.total_questions * (test.simulator_attributes?.score_correct || 0);
  if (test?.tests?.length) {
    for (const subtest of test.tests) {
      maxPoints += getTestMaxPoint(subtest);
    }
  }

  return maxPoints;
}
