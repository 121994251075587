import { SortTable } from '../../shared/table/model/base-table.model';
import { PaginatorEvent } from '../../shared/components/paginator/paginator.model';

export type DomainFilterType = 'cat1' | 'cat2' | 'cat3' | 'category_path';
export type CommonFilterType = 'str' | 'int' | 'bool' | 'float' | 'datetime';
export const DOMAIN_FILTERS_TYPE: Array<DomainFilterType> = ['cat1', 'cat2', 'cat3', 'category_path'];
export const COMMON_FILTERS_TYPE: Array<CommonFilterType> = ['str', 'int', 'bool', 'float', 'datetime'];

export type FilterType = DomainFilterType | CommonFilterType;

export interface FilterRawInfo {
  [column: string]: { filters: Array<FilterOperation>; type: FilterType };
}

interface BaseFilterInfo {
  column: string;
  filters: Array<FilterOperation>;
  type: FilterType;
}

export interface CommonFilter extends BaseFilterInfo {
  type: CommonFilterType;
}

export interface DomainFilter extends BaseFilterInfo {
  type: DomainFilterType;
  domain: Array<string>;
}

export type FilterInfo = CommonFilter | DomainFilter;

// SEARCH

export enum FilterOperation {
  EQ = 'EQ', // ==
  NOT_EQ = 'NOT_EQ', // !=
  GT = 'GT', // >
  LS = 'LS', // <
  GE = 'GE', // >=
  LE = 'LE', // <=
  LIKE = 'LIKE', // a string includes the given sequence of characters
  IN = 'IN', // include
  NOT_IN = 'NOT_IN', // !include
  IS_NULL = 'IS_NULL', // == null
  IS_NOT_NULL = 'IS_NOT_NULL', // != null
}

export interface FilterTable {
  column: string;
  operation?: FilterOperation;
}

export interface FilterWithValue extends FilterTable {
  operation: FilterOperation.EQ | FilterOperation.NOT_EQ | FilterOperation.GT | FilterOperation.LS | FilterOperation.GE | FilterOperation.LE;
  value: any;
}

export interface FilterWithString extends FilterTable {
  operation: FilterOperation.LIKE;
  value: string;
}

export interface FilterWithArray extends FilterTable {
  operation: FilterOperation.IN | FilterOperation.NOT_IN;
  values: Array<any>;
}

export interface FilterWithoutValue extends FilterTable {
  operation: FilterOperation.IS_NULL | FilterOperation.IS_NOT_NULL;
}

export type SearchFilter = FilterWithValue | FilterWithString | FilterWithArray | FilterWithoutValue;

export interface FutTableFilters {
  filters?: Array<SearchFilter>;
  sort?: SortTable;
  paginator?: PaginatorEvent;
}
