import { TestLeaderboard } from '../interfaces/leaderboard';
import { TestExtra } from '../interfaces/test_extra';
import { TranslocoService } from '@jsverse/transloco';
import { QuestionUserBehaviour } from '../interfaces/question';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Comparison, LeaderboardInfo, Test, TestGeneratorOptions, TestType } from '../interfaces/test';
import moment from 'moment';
import { map, retry, tap } from 'rxjs/operators';
import { Observable, of, ReplaySubject } from 'rxjs';
import { TestPoints } from '../model/fut-test.model';
import { SearchFilter } from '../filter/filter.model';
import { createRequestFilter } from '../filter/filter.util';
import { getTestMaxPoint } from '../util/test.util';
import { createCustomHeader } from '../util/http.util';
import { upperCaseFromUnderscore } from '../util/string.util';

@Injectable({
  providedIn: 'root',
})
export class TestService {
  private readonly LOCALSTORAGE_KEY = 'fut_tests';

  private readonly deleteEvent = new ReplaySubject<void>(1);

  constructor(
    private http: HttpClient,
    private translate: TranslocoService
  ) {}

  // Observable
  public generateNewSubtest(test: Test | string, name: string, count?: number): Observable<Test> {
    const url = environment.be_url + '/tests/subtest_add';
    const test_instance_id = typeof test === 'string' ? test : test.instance_id;

    return this.http.post<Test>(
      url,
      { subtest_name: name, count },
      createCustomHeader({
        target_resource: test_instance_id,
      })
    );
  }

  public getLeaderboardInfo(test: Test | string): Observable<LeaderboardInfo> {
    const url = environment.be_url + '/tests/leaderboard_info';
    const test_instance_id = typeof test === 'string' ? test : test.instance_id;

    return this.http
      .get<LeaderboardInfo>(
        url,
        createCustomHeader({
          target_resource: test_instance_id,
        })
      )
      .pipe(map(info => ({ ...info, points: info.points || 0 })));
  }

  public retry(test: Test | string): Observable<Test> {
    const url = environment.be_url + '/tests/copy';
    const test_instance_id = typeof test === 'string' ? test : test.instance_id;

    return this.http.post<Test>(
      url,
      undefined,
      createCustomHeader({
        target_resource: test_instance_id,
      })
    );
  }

  public getNewLevel(test: Test | string): Observable<Array<TestPoints>> {
    const url = environment.be_url + '/tests/milestone';
    const test_instance_id = typeof test === 'string' ? test : test.instance_id;

    return this.http.get<Array<TestPoints>>(
      url,
      createCustomHeader({
        target_resource: test_instance_id,
      })
    );
  }

  public getComparison(test: Test | string): Observable<Comparison> {
    const url = environment.be_url + '/tests/comparison';
    const test_instance_id = typeof test === 'string' ? test : test.instance_id;

    return this.http.get<Comparison>(
      url,
      createCustomHeader({
        target_resource: test_instance_id,
      })
    );
  }

  public submitTest(test: Test): Observable<Test> {
    const url = environment.be_url + '/tests/submit';
    localStorage.removeItem(this.LOCALSTORAGE_KEY);
    this.deleteEvent.next();
    localStorage.removeItem('show_referral');
    const test_instance_id = test.instance_id;

    return this.http
      .post<Test>(
        url,
        test,
        createCustomHeader({
          target_resource: test_instance_id,
        })
      )
      .pipe(retry(5));
  }

  public toggleVisibility(test: Test): Observable<Test> {
    const url = environment.be_url + '/tests/visibility';
    const testInstanceId = test.instance_id;

    return this.http
      .post<Test>(
        url,
        { hidden: !!test.hidden },
        createCustomHeader({
          target_resource: testInstanceId,
        })
      )
      .pipe(
        tap(() => {
          const tests: Array<Test> = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_KEY) || '[]');
          localStorage.setItem(this.LOCALSTORAGE_KEY, JSON.stringify(tests.filter(t => t.instance_id != testInstanceId)));
        })
      );
  }

  public getCompletedTests(): Observable<Array<Test>> {
    const localstorage_data = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_KEY) || '[]') as Array<Test>;
    if (localstorage_data && localstorage_data.length > 0) {
      return of(localstorage_data);
    }
    return this.searchTest().pipe(tap(tests => localStorage.setItem(this.LOCALSTORAGE_KEY, JSON.stringify(tests))));
  }

  public searchTest(filters?: Array<SearchFilter>): Observable<Array<Test>> {
    const filter = createRequestFilter(filters);
    return this.http.get<Array<Test>>(`${environment.be_url}/tests/${filter}`).pipe(
      map(tests =>
        tests.map(test => {
          const end_time = +test.end_time;
          const start_time = +test.start_time;
          const total_questions = +test.total_questions;
          const total_questions_blank = +test.total_questions_blank;
          const total_questions_correct = +test.total_questions_correct;
          const total_questions_wrong = +test.total_questions_wrong;

          return {
            ...test,
            end_time,
            start_time,
            total_questions,
            total_questions_blank,
            total_questions_correct,
            total_questions_wrong,
          };
        })
      ),
      map(tests => tests.sort((a, b) => b.end_time - a.end_time))
    );
  }

  public getShareId(test: Test | string): Observable<{ id: string }> {
    const testId = typeof test === 'string' ? test : test.instance_id;
    return this.http.post<{ id: string }>(
      environment.be_url + '/tests/share',
      undefined,
      createCustomHeader({
        target_resource: testId,
      })
    );
  }

  public generate(options: TestGeneratorOptions): Observable<Test> {
    if (!options.name) {
      options.name = this.translate.translate('simulation.title', { date: moment().format('L') });
    }
    return this.http.post<Test>(environment.be_url + '/tests/generate', { options });
  }

  public deletedTestCache(): Observable<void> {
    return this.deleteEvent.asObservable();
  }

  public get(test_instance_id: string): Observable<Test> {
    return this.http
      .get<Test>(
        environment.be_url + '/tests/',
        createCustomHeader({
          target_resource: test_instance_id,
        })
      )
      .pipe(map(test => this.setQuestionTestUUID(test)));
  }

  private setQuestionTestUUID(test: Test): Test {
    test.questions_user_behaviour.forEach(q => (q.test_uuid = test.instance_id));
    test.tests = test.tests.map(t => this.setQuestionTestUUID(t));

    return test;
  }

  public onQuestionAnsweredInTest(question: QuestionUserBehaviour, question_index: number, test: Test): Observable<void> {
    return this.http.post<void>(environment.be_url + '/tests/question', {
      question,
      question_index,
      partition_uuid: test.partition_id,
      instance_uuid: test.instance_id,
    });
  }

  public saveTest(test: Partial<Test>): Observable<Test> {
    return this.http.post<Test>(environment.be_url + '/tests/', { test: test });
  }

  public delete(test: Test): Observable<void> {
    return this.http.delete<void>(
      environment.be_url + '/tests/',
      createCustomHeader({
        target_resource: test.instance_id,
      })
    );
  }

  public getTestMaxPoints(test: Test): number {
    if (!test.tests?.length) return getTestMaxPoint(test);

    return test.max_points;
  }

  public testInfoToOptions(
    test: TestExtra,
    points: {
      correct: number;
      wrong: number;
      blank: number;
    }
  ): TestGeneratorOptions {
    const testExtraType = test.type;
    let testType: TestType;

    if (testExtraType === 'admin_custom' || testExtraType === 'custom') testType = 'custom';
    else if (testExtraType === 'category_test') testType = 'subject';
    else if (testExtraType === 'prefab') testType = 'test';
    else testType = testExtraType;

    return {
      name: test.title + ' ' + moment().format('L'),
      score_correct: points.correct,
      score_wrong: points.wrong,
      score_blank: points.blank,
      duration: test.duration!,
      type: testType,
      questions_generator: test.questions_each_category!.map(q => ({
        category_path: q.category_path,
        count: q.questions_count,
        difficulty: 0,
        name: q.category_path
          .split('.')[0]
          .split('_')
          .map(w => upperCaseFromUnderscore(w))
          .join(' '),
      })),
    };
  }

  getQuestionsCount(test: Test): number {
    let count = 0;

    count += (test.questions_user_behaviour || []).length;

    for (const subtest of test.tests) {
      count += this.getQuestionsCount(subtest);
    }

    return count;
  }

  getDuration(test: Test): number {
    let duration = 0;

    duration += +test.duration || 0;
    for (const subtest of test.tests) {
      duration += this.getDuration(subtest);
    }

    return duration;
  }

  getCategories(test: Test): Array<string> {
    let categories: Array<string> = [];

    for (const question of test.questions_user_behaviour) {
      categories.push(question.category);
    }
    for (const subtest of test.tests) {
      categories = categories.concat(this.getCategories(subtest));
    }

    return Array.from(new Set(categories));
  }

  getLeaderboard(test_instance_id: string): Observable<Array<TestLeaderboard>> {
    return this.http
      .get<Array<TestLeaderboard>>(
        environment.be_url + '/tests/leaderboard',
        createCustomHeader({
          target_resource: test_instance_id,
        })
      )
      .pipe(
        tap(leaderboards => {
          leaderboards
            ?.filter(leaderboard => !leaderboard?.user_picture && leaderboard?.user_name)
            .forEach(leaderboard => {
              const userFullName = leaderboard?.user_name?.toUpperCase().split('_').join('+');
              leaderboard.user_picture = `https://ui-avatars.com/api/?background=152148&font-size=.6&color=fff&name=${userFullName}&bold=True&size=1024`;
            });
        })
      );
  }

  getSharedInfo(test_token: string): Observable<{ test: Test }> {
    return this.http.get<{ test: Test }>(
      environment.be_url + '/tests/copy',
      createCustomHeader({
        target_resource: test_token,
      })
    );
  }

  getSharedTest(test_token: string): Observable<Test> {
    return this.http.post<Test>(
      environment.be_url + '/tests/copy',
      undefined,
      createCustomHeader({
        target_resource: test_token,
      })
    );
  }
}
