import {
  COMMON_FILTERS_TYPE,
  CommonFilterType,
  DOMAIN_FILTERS_TYPE,
  DomainFilter,
  DomainFilterType,
  FilterInfo,
  FilterOperation,
  FilterTable,
  FilterType,
  FutTableFilters,
  SearchFilter,
} from './filter.model';
import { SortTable } from '../../shared/table/model/base-table.model';
import { PaginatorEvent } from '../../shared/components/paginator/paginator.model';

export function createFilter(column: string, operation: FilterOperation, value?: any): SearchFilter {
  if (operation === FilterOperation.IS_NULL || operation === FilterOperation.IS_NOT_NULL) {
    return { column, operation };
  }
  if (value == null) {
    throw new Error('Value is not present!');
  }
  if (operation === FilterOperation.IN || operation === FilterOperation.NOT_IN) {
    const values = Array.isArray(value) ? value : [value];
    return { column, operation, values };
  }

  return { operation, column, value };
}

export function createRequestFilter(filters?: Array<SearchFilter>): string {
  const filter = convertFiltersToParams(filters);
  if (!filter) {
    return '';
  }
  return `?${filter}`;
}

function convertFiltersToParams(filters?: Array<SearchFilter>): string {
  if (!filters?.length) {
    return '';
  }
  const encodedFilters = encodeFilters(filters);
  return `filter=${encodedFilters}`;
}

function convertSortToParams(sort?: SortTable): string {
  if (!sort) {
    return '';
  }
  return `sort_col=${sort.col}&sort_direction=${sort.direction}`;
}

function convertPaginatorToParams(paginator?: PaginatorEvent): string {
  if (!paginator) {
    return '';
  }
  let pag = `page_size=${paginator.pageSize}&page_selected=${paginator.pageSelected}`;
  if (paginator.getTotal) {
    pag += `&get_total=${paginator.getTotal}`;
  }
  return pag;
}

export function createRequestTableFilter(tableFilters?: FutTableFilters): string {
  if (!tableFilters) {
    return '';
  }
  const { filters, sort, paginator } = tableFilters;
  const params = [];
  if (filters?.length) {
    params.push(`filter=${encodeFilters(filters)}`);
  }
  if (sort) {
    params.push(convertSortToParams(sort));
  }
  if (paginator) {
    params.push(convertPaginatorToParams(paginator));
  }
  const requestParams = params.filter(p => !!p).join('&');
  if (!requestParams) {
    return '';
  }
  return `?${requestParams}`;
}

export function encodeFilters(filters: Array<FilterTable>): string {
  return btoa(JSON.stringify(filters || []));
}

export function isCommonFilterType(filter: FilterType): filter is CommonFilterType {
  return COMMON_FILTERS_TYPE.find(value => filter === value) != null;
}

export function isDomainFilterType(filter: FilterType): filter is DomainFilterType {
  return DOMAIN_FILTERS_TYPE.find(value => filter === value) != null;
}

export function isDomainFilter(filter: FilterInfo): filter is DomainFilter {
  return isDomainFilterType(filter.type);
}
